<template>
  <div class="drainage-wrapper">
    <div class="panels-wrapper">
      <div class="title">多形式沉淀客户流量 增长变现源源不断</div>
      <div class="slides-container">
        <div 
          v-for="(item, index) in slideData"
          :key="index"
          class="slide-item"
          :class="[item.bgClass, currentSlideIndex === index? 'active' : '']"
          @mouseover="onSlideChange(index)"
        >
          <div class="name">{{item.name}}</div>
          <transition name="fade">
            <div v-show="currentSlideIndex === index" class="info">
              <pre class="title">{{item.title}}</pre>
              <div class="functions">
                <div 
                  v-for="(f, i) in item.functions"
                  :key="i"
                  class="function-item"
                  @mouseenter="onItemEnter(f)"
                  @mouseleave="onItemLeave()"
                >
                  <img :src="currentHoverFunction === f.text ? f.hoverIcon : f.icon" alt="" class="icon">
                  <span class="text">{{f.text}}</span>
                </div>
              </div>
            </div>
          </transition>
          <div v-show="currentSlideIndex === index" class="btn" @click="jumpCollect">快速获取解决方案</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlideIndex: 0,
      currentHoverFunction: '',
      slideData: [
        {
          bgClass: 'multi-channel',
          name: '多渠道',
          title: `支持通过各类线上渠道获取客户\n方式快捷便利`,
          functions: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265158404598127.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881471451.png',
              text: '信息流广告'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265158404451809.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881404793.png',
              text: '智能外呼'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265158404357272.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881363941.png',
              text: '智能短信'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265158404219883.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881325522.png',
              text: '直播平台'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265158404067399.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881287734.png',
              text: '腾讯生态'
            }
          ]
        },
        {
          bgClass: 'multi-scene',
          name: '多场景',
          title: `支持通过各类场景获取客户\n全面触达有效人群`,
          functions: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265160610065922.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881244475.png',
              text: '门店消费'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265160610013839.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881209228.png',
              text: '直播活动'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265160609954969.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881172940.png',
              text: '微信沟通'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265160609881046.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881126432.png',
              text: '短视频传播'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265160609358572.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638881043042.png',
              text: '公众号传播'
            },
          ]
        },
        {
          bgClass: 'no-limit',
          name: '无上限',
          title: `深度融合企业微信的扩建能力\n可无限拓展社群`,
          functions: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265161799111901.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638880546600.png',
              text: '无社群上限'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265161798784502.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638879377834.png',
              text: '无加粉上限'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265161798842567.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638878682924.png',
              text: '无封号风险'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16265161798916511.png',
              hoverIcon: 'https://mktv-in-cdn.mockuai.com/16267638878159273.png',
              text: '瞬间加粉'
            },
          ]
        }
      ]
    }
  },
  methods: {
    onSlideChange(index) {
      this.currentSlideIndex = index
    },
    onItemEnter(f) {
      this.currentHoverFunction = f.text
    },
    onItemLeave() {
      this.currentHoverFunction = ''
    },
    jumpCollect() {
      window.open('/collect-scrm')
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../../style/common.less';

  .drainage-wrapper {
    .panels-wrapper {
      box-sizing: border-box;
      .pxToVw(height, 954);
      .pxToVw(padding-top, 112);  
      >.title {
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        .pxToVw(margin-bottom, 72);
        text-align: center;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .slides-container {
        margin: 0 240 * @unitVw;
        display: flex;
        align-items: center;
        .slide-item {
          box-sizing: border-box;
          transition: all .35s;
          position: relative;
          .pxToVw(width, 300);
          .pxToVw(height, 600);
          padding: 31*@unitVw 40*@unitVw 0;
          .pxToVw(margin-right, 20);
          .pxToVw(border-radius, 16);
          background-size: cover;
          background-repeat: no-repeat;
          background-color: #001554;
          &:last-child {
            margin-right: 0;
          }
          &.multi-channel {
            background-image: url(https://mktv-in-cdn.mockuai.com/16265143504747111.png);
          }
          &.multi-scene {
            background-image: url(https://mktv-in-cdn.mockuai.com/16265143504556779.png);
          }
          &.no-limit {
            background-image: url(https://mktv-in-cdn.mockuai.com/16265143504202707.png);
          }
          &.active {
            .pxToVw(width, 800);
            &.multi-channel {
              background-image: url(https://mktv-in-cdn.mockuai.com/16265143503505476.png);
            }
            &.multi-scene {
              background-image: url(https://mktv-in-cdn.mockuai.com/16265143504658915.png);
            }
            &.no-limit {
              background-image: url(https://mktv-in-cdn.mockuai.com/16265143504772838.png);
              .functions {
                .pxToVw(width, 472);
                .function-item {
                  &:nth-child(2n) {
                    margin-right: 0;
                  }
                  &:nth-child(3n) {
                    .pxToVw(margin-right, 24);
                  }
                }
              }
            }
          }
          .name {
            position: absolute;
            .pxToVw(left, 40);
            .pxToVw(bottom, 42);
            .pxToVw(font-size, 36);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
          }
          .info {
            overflow: hidden;
            &.fade-enter-active, .fade-leave-active {
              transition: opacity 1s;
            }
            &.fade-enter, .fade-leave-to {
              opacity: 0;
            }
            .title {
              .pxToVw(width, 504);
              margin: 0;
              .pxToVw(margin-bottom, 43);
              .pxToVw(font-size, 32);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
            .functions {
              display: flex;
              flex-wrap: wrap;
              .pxToVw(width, 720);
              .pxToVw(height, 324);
              .function-item {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .pxToVw(width, 224);
                .pxToVw(height, 138);
                .pxToVw(margin-right, 24);
                .pxToVw(margin-bottom, 24);
                .pxToVw(border-radius, 8);
                background-color: rgba(255, 255, 255, .3);
                border: 1px solid rgba(255, 255, 255, 0.33);
                backdrop-filter: blur(8px);
                cursor: pointer;
                &:nth-child(3n) {
                  margin-right: 0;
                }
                &:hover {
                  background-color: #fff;
                  .text {
                    color: #333;
                  }
                }
                .icon {
                  .pxToVw(width, 58);
                  .pxToVw(height, 58);
                  .pxToVw(margin-bottom, 8);
                }
                .text {
                  .pxToVw(line-height, 25);
                  .pxToVw(font-size, 18);
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }
            }
          }
          .btn {
            box-sizing: border-box;
            position: absolute;
            .pxToVw(right, 40);
            .pxToVw(bottom, 32);
            .pxToVw(width, 200);
            .pxToVw(height, 48);
            .pxToVw(line-height, 48);
            text-align: center;
            .pxToVw(border-radius, 23);
            background-color: #fff;
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333; 
            cursor: pointer;
            &:hover {
              background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
