<template>
  <div class="scrm-container">
    <shrinkable-header ref="header" class="scrm-header">
      <p-header :fixed="false" type="flow"></p-header>
      <sub-header title="酷管家" titleColor="#0F58F0">
        <template v-slot:title>
          <div class="box">
            <img src="https://mktv-in-cdn.mockuai.com/16275308689128813.png" class="icon" />
            <span class="text">酷管家</span>
          </div>
        </template>
        <ul class="nav">
          <li class="nav-item" data-menuanchor="private" @click="changeMoveMode">
            <a href="#private">私域解决方案</a>
          </li>
          <li class="nav-item" data-menuanchor="drainage" @click="changeMoveMode">
            <a href="#drainage">引流获客</a>
          </li>
          <li class="nav-item" data-menuanchor="invigorate" @click="changeMoveMode">
            <a href="#invigorate">促活转化</a>
          </li>
          <li class="nav-item" data-menuanchor="fans" @click="changeMoveMode">
            <a href="#fans">粉丝管理</a>
          </li>
        </ul>
      </sub-header>
    </shrinkable-header>
    <tools-bar ref="toolsBar" @backtop="onBacktop"></tools-bar>
    <div id="scrm-fullpage">
      <div ref="firstSection" class="section fp-auto-height-responsive">
        <private></private>
      </div>
      <div class="section fp-auto-height">
        <drainage></drainage>
      </div>
      <div class="section fp-auto-height">
        <invigorate></invigorate>
      </div>
      <div class="section fp-auto-height-responsive">
        <fans></fans>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from 'js-base64'
import Header from '@/layout/Header.vue'
import SubHeader from '@/components/SubHeader.vue'
import ShrinkableHeader from '@/components/ShrinkableHeader.vue'
import Private from '@/components/scrm/Private.vue'
import Drainage from '@/components/scrm/Drainage.vue'
import Invigorate from '@/components/scrm/Invigorate.vue'
import Fans from '@/components/scrm/Fans.vue'
import ToolsBar from '@/components/ToolsBar.vue'

let isFirstSection = false
let anchorMove = false

export default {
  components: {
    'p-header': Header,
    SubHeader,
    ShrinkableHeader,
    Private,
    Drainage,
    Invigorate,
    Fans,
    ToolsBar
  },
  head: {
    title: '酷管家-构建私域增长闭环，高效进行客户管理',
    meta: [
      {
        name: 'Keywords',
        content: '酷管家  SCRM   私域流量   私域运营   私域管理   魔筷酷管家   酷管家SCRM'
      },
      {
        name: 'Description',
        content: '酷管家是魔筷科技基于企业微信开发的客户与营销管理平台，为用户提供粉丝运营增长和价值拓展的全功能私域流量运营服务。'
      }
    ]
  },
  mounted() {
    this.initFullPage()
    this.$refs.toolsBar.show()
  },
  destroyed() {
    window.fullpage_api.destroy()
  },
  methods: {
    initFullPage() {
      require('fullpage.js/vendors/scrolloverflow.min.js')
      const Fullpage = require("fullpage.js");
      const vm = this
      new Fullpage('#scrm-fullpage', {
        licenseKey: decode(window.__INITIAL_STATE__.fullpageKey),
        anchors: ['private', 'drainage', 'invigorate', 'fans'],
        menu: '.sub-header .nav',
        verticalCentered: false,
        scrollOverflow: true,
        fixedElements: '.scrm-header, .video-modal',
        afterLoad(origin, dest) {
          isFirstSection = dest.isFirst
          let scrollEle = document.querySelector('.fp-section.active .fp-scrollable')
          let iscroll = scrollEle && scrollEle.fp_iscrollInstance
          if (iscroll && anchorMove) {
            iscroll.scrollTo(0, 0, 600)
          }
          
          if(dest.isFirst) {
            vm.$refs.header.changeStyle('top', 0)
          } else {
            vm.$refs.header.changeStyle('top', '-90px')
          }
        },
        afterRender() {
          window.addEventListener('wheel', () => {
            anchorMove = false
            if (isFirstSection) {
              let ele = vm.$refs.firstSection.querySelector('.fp-scrollable .fp-scroller')
              const transformStr = window.getComputedStyle(ele).getPropertyValue('transform')
              const y = Math.abs(transformStr.split(',')[transformStr.split(',').length - 1].split(')')[0])
              if (y <= 90) {
                vm.$refs.header.changeStyle('top', `-${y}px`)
                vm.$refs.firstSection.style.paddingTop = `${150 - y}px`
              } else {
                vm.$refs.header.changeStyle('top', '-90px')
                vm.$refs.firstSection.style.paddingTop = '60px'
              }
              window.fullpage_api.reBuild() 
            }
          })
        }
      })
    },
    changeMoveMode() {
      anchorMove = true
    },
    onBacktop() {
      window.fullpage_api.moveTo(1)
      let iscroll = document.querySelector('.fp-section.active .fp-scrollable') && document.querySelector('.fp-section.active .fp-scrollable').fp_iscrollInstance
      if (iscroll) {
        iscroll.scrollTo(0, 0, 600)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    display: flex;
    align-items: center;
    .icon {
      width: 28px;
      height: 28px;
      margin-right: 9px;
    }
    .text {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0f58f0;
    }
  }
  .nav { 
    .nav-item {
      &.active {
        a {
          &::after {
            content: '';
            background-color: #0f58f0 !important;
          }
        }
      }
    }
  }
  .scrm-container {
    .section {
      transition: all .5s;
      &:nth-child(1) {
        padding-top: 150px;
      }
      &:nth-child(2) {
        padding-top: 60px;
      }
      &:nth-child(3) {
        padding-top: 60px;
      }
      &:nth-child(4) {
        padding-top: 60px;
      }
    }
  }
</style>

