<template>
  <div class="fans-wrapper">
    <div class="lifecycle-container">
      <div class="title">全生命周期管理 实现粉丝价值最大化</div>
      <div class="content-box">
        <div class="tabs">
          <div 
            v-for="(item, index) in tabsData"
            :key="index"
            class="tab-item"
            :class="currentTabIndex === index ? 'active' : ''"
            @mouseover="onTabChange(index)"
          >
            {{item.name}}
          </div>
        </div>
        <div 
          class="tab-panel"
          :class="currentTab.bgClass"
          ref="tabPanel"
        >
          <div class="details">
            <div 
              v-for="(d, i) in currentTab.details"
              :key="i"
              class="detail-item"
            >
              <img :src="d.icon" alt="" class="icon">
              <div class="right">
                <div class="text">{{d.text}}</div>
                <pre class="desc">{{d.desc}}</pre>
              </div>
            </div>
          </div>
          <div class="btn" @click="jumpCollect">快速获取解决方案</div>
        </div>
      </div>
    </div>
    <div class="industry-container">
      <div class="title">适用行业</div>
      <div class="content-box">
        <div 
          v-for="(item, index) in industryData"
          :key="index"
          class="card-item"
          @mouseenter="onIndustryChange(index)"
          @mouseleave="onIndustryLeave"
        >
          <img :data-src="item.icon" alt="" class="icon">
          <span class="text">{{item.text}}</span>
          <div 
            v-show="currentIndustryindex === index" class="cover" 
          >
            <img :src="item.image" alt="" class="image">
            <span class="text">{{item.text}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="use-container">
      <div class="title">你的同行都已开始使用，最好的入局时间就是现在！</div>
      <div class="btn" @click="jumpCollect">快速获取解决方案</div>
    </div>
    <p-footer type="flow"></p-footer>
  </div>
</template>

<script>
import Footer from '@/layout/Footer.vue'
export default {
  components: {
    'p-footer': Footer
  },
  data() {
    return {
      currentTabIndex: 0,
      tabsData: [
        {
          name: '产品化管理',
          bgClass: 'product',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652225074991.png',
              text: '一键添加',
              desc: '活码与标签绑定；无需验证直接添加'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652225049456.png',
              text: '激活转化',
              desc: '按标签内容再批量维护；群内工具，引入直播'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652225008159.png',
              text: '即时维护',
              desc: '流失提醒；关键节点自动化运营'
            }
          ]
        },
        {
          name: '数据化管理',
          bgClass: 'data',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652224971612.png',
              text: '标签管理',
              desc: `属性数据：性别 I 爱好 I 地理位置…\n消费数据：购买频次 I 购买时间…`
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652224933027.png',
              text: '可视化数据',
              desc: '粉丝生命周期数据；粉丝活跃程度数据'
            }
          ]
        },
        {
          name: '风险控制',
          bgClass: 'risk',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652224909446.png',
              text: '粉丝资产管控',
              desc: `客户明细查询和备档，支持标签化检索\n管理员离职后，支持审核验证后转移至新人`
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652224872261.png',
              text: '素材资产管控',
              desc: `支持对内容素材的共享和使用\n管控素材包括文本、图片和视频`
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266652224378850.png',
              text: '敏感内容管控',
              desc: `设定敏感词，对群内容进行监控\n查看敏感信息，并对上下文和行为进行监控`
            }
          ]
        }
      ],
      currentIndustryindex: -1,
      industryData: [
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446598066.png',
          text: '直播电商',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722626167.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446553703.png',
          text: '母婴洗护 ',
          image: 'https://mktv-in-cdn.mockuai.com/16266778721976741.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446493688.png',
          text: '服饰鞋包',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722047097.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446442540.png',
          text: '美妆个护',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722157011.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446412232.png',
          text: '食品快消',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722241813.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446369486.png',
          text: '日用百货',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722277199.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446332821.png',
          text: '3C数码',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722353535.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446296423.png',
          text: '餐饮服务',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722436645.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775446256978.png',
          text: '家居家装 ',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722541891.png'
        },
        {
          icon: 'https://mktv-in-cdn.mockuai.com/16266775445095891.png',
          text: '宠物服务',
          image: 'https://mktv-in-cdn.mockuai.com/16266778722582360.png'
        }
      ]
    }
  },
  computed: {
    currentTab() {
      return this.tabsData[this.currentTabIndex]
    }
  },
  methods: {
    onTabChange(index) {
      if (this.currentTabIndex !== index) {
        this.$refs.tabPanel.classList.remove('fade-in')
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.$refs.tabPanel.classList.add('fade-in')
          })
        }) 
      }
      this.currentTabIndex = index
    },
    onIndustryChange(index) {
      this.currentIndustryindex = index
    },
    onIndustryLeave() {
      this.currentIndustryindex = -1
    },
    jumpCollect() {
      window.open('/collect-scrm')
    }
  }
}
</script>


<style lang="less" scoped>
  @import '../../style/common.less';

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fade-in 1s ease 0s 1 normal both;
  }

  .fans-wrapper {
    .lifecycle-container {
      box-sizing: border-box;
      .pxToVw(height, 1020);
      .pxToVw(padding-top, 92);
      background: linear-gradient(133deg, #00092A 0%, #001554 100%);
      >.title {
        .pxToVw(margin-bottom, 75);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .content-box {
        margin: 0 240*@unitVw;
        .tabs {
          display: flex;
          align-items: center;
          justify-content: center;
          .pxToVw(margin-bottom, 40);
          .tab-item {
            box-sizing: border-box;
            .pxToVw(margin-right, 40);
            .pxToVw(width, 180);
            .pxToVw(height, 60);
            .pxToVw(line-height, 60);
            text-align: center;
            .pxToVw(border-radius, 30);
            border: 1px solid #fff;
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            &.active {
              background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
              border: none;
              color: #fff;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .tab-panel {
          box-sizing: border-box;
          .pxToVw(height, 600);
          .pxToVw(padding-left, 800);
          .pxToVw(padding-top, 77);
          .pxToVw(border-radius, 16);
          background-size: cover;
          background-repeat: no-repeat;
          &.product {
            // background-image: url(https://mktv-in-cdn.mockuai.com/16266671317575378.png);
            background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266671317575378.png) top left/cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
            .btn {
              .pxToVw(margin-top, 77);
            }
          }
          &.data {
            .pxToVw(padding-top, 125);
            // background-image: url(https://mktv-in-cdn.mockuai.com/16266671317869193.png);
            background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266671317869193.png) top left/cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
             .btn {
              .pxToVw(margin-top, 124);
            }
          }
          &.risk {
            // background-image: url(https://mktv-in-cdn.mockuai.com/16266671317915413.png);
            background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266671317915413.png) top left/cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
             .btn {
              .pxToVw(margin-top, 52);
            }
            .details {
              .detail-item {
                .pxToVw(margin-bottom, 27);
              }
            }
          }
          .details {
            .detail-item {
              display: flex;
              align-items: center;
              .pxToVw(margin-bottom, 52);
              &:last-child {
                margin-bottom: 0;
              }
              .icon {
                .pxToVw(width, 58);
                .pxToVw(height, 58);
                .pxToVw(margin-right, 19);
              }
              .right {
                .text {
                  .pxToVw(margin-bottom, 9);
                  .pxToVw(line-height, 33);
                  .pxToVw(font-size, 24);
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #FFFFFF;
                }
                .desc {
                  margin: 0;
                  .pxToVw(line-height, 25);
                  .pxToVw(font-size, 18);
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }
            }
          }
          .btn {
            .pxToVw(width, 320);
            .pxToVw(height, 60);
            .pxToVw(line-height, 60);
            text-align: center;
            .pxToVw(border-radius, 30);
            background-color: #fff;
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            &:hover {
              background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .industry-container {
      box-sizing: border-box;
      .pxToVw(height, 764);
      .pxToVw(padding-top, 91);
      background-image: url(https://mktv-in-cdn.mockuai.com/16266790343005875.png);
      background-size: cover;
      background-repeat: no-repeat;
      >.title {
        .pxToVw(margin-bottom, 73);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .content-box {
        margin: 0 240*@unitVw;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .card-item {
          box-sizing: border-box;
          position: relative;
          .pxToVw(width, 272);
          .pxToVw(height, 168);
          .pxToVw(padding-top, 32);
          .pxToVw(padding-bottom, 36);
          .pxToVw(margin-right, 20);
          .pxToVw(margin-bottom, 20);
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
          border: 1px solid #ddd;
          .pxToVw(border-radius, 8);
          overflow: hidden;
          cursor: pointer;
          &:nth-child(5n) {
            margin-right: 0;
          }
          .icon {
            .pxToVw(width, 58);
            .pxToVw(height, 58);
            .pxToVw(margin-bottom, 9);
          }
          .text {
            .pxToVw(line-height, 33);
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .cover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #0f58f0;
            .image {
              width: 100%;
            }
            .text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .use-container {
      box-sizing: border-box;
      .pxToVw(height, 416);
      .pxToVw(padding-top, 113);
      background-color: #0F58F0;
      background-image: url(https://mktv-in-cdn.mockuai.com/16266792527479141.png);
      background-repeat: no-repeat;
      background-size: cover;
      .title {
        .pxToVw(margin-bottom, 73);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
      .btn {
        margin: 0 auto;
        .pxToVw(width, 320);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        .pxToVw(border-radius, 30);
        background-color: #fff;
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
</style>

