<template>
  <div class="invigorate-wrapper">
    <div class="banner-container">
      <div class="title">基于工具及运营服务 提升复购形成裂变</div>
      <div class="tabs-container">
        <div class="tabs">
          <div 
            v-for="(item, index) in tabsData"
            :key="index"
            class="tab-item"
            :class="currentTabIndex === index ? 'active' : ''"
            @mouseover="onTabChange(index)"
          >
            {{item.name}}
          </div>
        </div>
        <div class="content-box">
          <div ref="tabInfo" class="info">
            <div class="details">
              <div 
                v-for="(d, i) in currentTab.details"
                :key="i"
                class="detail-item"
              >
                <img :src="d.icon" alt="" class="icon">
                <div class="right">
                  <div class="text">{{d.text}}</div>
                  <div class="desc">{{d.desc}}</div>
                </div>
              </div>
            </div>
            <div class="btn" @click="jumpCollect">快速获取解决方案</div>
          </div>
          <div ref="bannerBox" class="banner-box">
            <img :src="currentTab.image" alt="" class="banner">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTabIndex: 0,
      tabsData: [
        {
          name: '多场景互动',
          image: 'https://mktv-in-cdn.mockuai.com/16266611710233967.png',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155598858.png',
              text: '群发场景',
              desc: '新入群 I 大促活动 I 宠粉节 I 福利放送'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155498314.png',
              text: '特殊场景',
              desc: '粉丝生日 I 回访 I 特殊关怀 I 热点沟通'
            }
          ]
        },
        {
          name: '精准运营',
          image: 'https://mktv-in-cdn.mockuai.com/16266611710278717.png',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155477909.png',
              text: '标签定制',
              desc: '根据数据，由专家帮用户进行有意义的标签分类'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155426372.png',
              text: '用户周期维管',
              desc: '根据用户不同的关系状态，进行有针对性的分类维系'
            }
          ]
        },
        {
          name: '促活转化',
          image: 'https://mktv-in-cdn.mockuai.com/16266611710354906.png',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155383242.png',
              text: '线上活动',
              desc: '秒杀 I 抽奖 I 领福利 I 打卡积分 I 直播策划'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155319256.png',
              text: '线下活动',
              desc: '粉丝见面会 I 促销活动'
            }
          ]
        },
        {
          name: '分销裂变',
          image: 'https://mktv-in-cdn.mockuai.com/16266611710387822.png',
          details: [
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155282724.png',
              text: '促销裂变',
              desc: '拼团 I 砍价 I 分销招募 I 朋友圈分享'
            },
            {
              icon: 'https://mktv-in-cdn.mockuai.com/16266616155024356.png',
              text: '会员裂变',
              desc: '拼团裂变 I 满件抽奖'
            }
          ]
        }
      ]
    }
  },
  computed: {
    currentTab() {
      return this.tabsData[this.currentTabIndex]
    }
  },
  methods: {
    onTabChange(index) {
      if (this.currentTabIndex !== index) {
        this.$refs.tabInfo.classList.remove('fade-in-animation')
        // this.$refs.bannerBox.classList.remove('fade-in-animation')
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.$refs.tabInfo.classList.add('fade-in-animation')
            // this.$refs.bannerBox.classList.add('fade-in-animation')
          })
        })
      }
      this.currentTabIndex = index
    },
    jumpCollect() {
      window.open('/collect-scrm')
    }
  }
}
</script>


<style lang="less" scoped>
  @import '../../style/common.less';

  @keyframes fade-in-animation {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .fade-in-animation {
    animation: fade-in-animation .4s ease 80ms 1 normal both;
  }

  .invigorate-wrapper {
    .pxToVw(height, 1000);
    .banner-container {
      box-sizing: border-box;
      .pxToVw(height, 520);
      .pxToVw(padding-top, 94);
      background: no-repeat url(https://mktv-in-cdn.mockuai.com/16266603265779075.png) left top / cover, linear-gradient(133deg, #00092A 0%, #001554 100%);
      >.title {
        .pxToVw(margin-bottom, 76);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .tabs-container {
        margin: 0 240*@unitVw;
        .tabs {
          display: flex;
          align-items: center;
          justify-content: center;
          .pxToVw(margin-bottom, 40);
          .tab-item {
            box-sizing: border-box;
            .pxToVw(width, 180);
            .pxToVw(height, 60);
            .pxToVw(line-height, 60);
            .pxToVw(margin-right, 40);
            text-align: center;
            .pxToVw(border-radius, 30);
            border: 1px solid #fff;
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            &.active {
              background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
              border: none;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .content-box {
          position: relative;
          box-sizing: border-box;
          width: 100%;
          .pxToVw(height, 600);
          .pxToVw(padding-left, 35);
          .pxToVw(padding-top, 94);
          .pxToVw(border-radius, 16);
          background-image: url(https://mktv-in-cdn.mockuai.com/16266629725051762.png);
          background-size: cover;
          background-repeat: no-repeat;
          .info {
            .details {
              .pxToVw(margin-bottom, 58);
              overflow: hidden;
              .detail-item {
                display: flex;
                align-items: center;
                .pxToVw(margin-bottom, 72);
                .icon {
                  .pxToVw(width, 58);
                  .pxToVw(height, 58);
                  object-fit: cover;
                  .pxToVw(margin-right, 19);
                }
                .right {
                  .text {
                    .pxToVw(margin-bottom, 9);
                    .pxToVw(line-height, 33);
                    .pxToVw(font-size, 24);
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                  }
                  .desc {
                    .pxToVw(max-width, 380);
                    .pxToVw(line-height, 25);
                    .pxToVw(font-size, 18);
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                  }
                }
              }
            }
            .btn {
              .pxToVw(width, 320);
              .pxToVw(height, 60);
              .pxToVw(line-height, 60);
              text-align: center;
              box-shadow: 0px 8px 18px 0px rgba(15, 88, 240, 0.21);
              background-color: #0f58f0;
              .pxToVw(border-radius, 30);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(255, 255, 255, .8);
              cursor: pointer;
              &:hover {
                font-weight: 500;
                color: #fff;
              }
            }
          }
          .banner-box {
            position: absolute;
            .pxToVw(top, 42);
            .pxToVw(right, -13);
            .pxToVw(width, 883);
            .pxToVw(height, 549);
            // background-image: url(https://mktv-in-cdn.mockuai.com/16266611709954886.png);
            background-image: url(https://mktv-in-cdn.mockuai.com/16275788344987534.png);
            background-size: cover;
            background-repeat: no-repeat;
            .banner {
              position: absolute;
              .pxToVw(top, 51);
              .pxToVw(left, 116);
              .pxToVw(width, 609);
              .pxToVw(height, 382);
            }
          }
        }
      }
    }
  }
</style>
