<template>
  <div class="private-wrapper">
    <div class="banner-wrapper">
      <div class="info">
        <h3 class="title">
          魔筷酷管家<br>轻松管理你的粉丝库
        </h3>
        <div class="desc">酷管家强势来袭 私域营销理想之选</div>
        <div class="actions">
          <div class="btn btn-white" @click="jumpCollect">快速获取解决方案</div>
          <!-- <div class="btn">观看视频</div> -->
        </div>
      </div>
      <!-- <div class="banner-box">
        <div 
          v-for="(item, index) in bannerData"
          :key="index"
          class="icon-box"
        >
          <img :src="item.icon" alt="" class="icon">
          <span class="text">{{item.text}}</span>
        </div>
      </div> -->
    </div>
    <div class="intro-wrapper">
      <div class="title">构建“私域增长”闭环 高效进行客户管理</div>
      <div class="desc">酷管家是魔筷科技基于企业微信开发的客户与营销管理平台，为用户提供粉丝运营增长和价值拓展的全功能私域流量运营服务</div>
      <div class="functions">
        <div 
          v-for="(item, index) in introData"
          :key="index"
          class="function-item"
        >
          <img :src="item.img" alt="" class="image">
          <div class="text">{{item.text}}</div>
        </div>
      </div>
      <div class="btn" @click="jumpCollect">快速获取解决方案</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerData: [
        {
          text: '私域运营',
          icon: 'https://mktv-in-cdn.mockuai.com/16265065624463635.png'
        },
        {
          text: '引流获客',
          icon: 'https://mktv-in-cdn.mockuai.com/16265065624373393.png'
        },
        {
          text: '促活转化',
          icon: 'https://mktv-in-cdn.mockuai.com/16265065624224032.png'
        },
        {
          text: '粉丝管理',
          icon: 'https://mktv-in-cdn.mockuai.com/16265065624278609.png'
        }
      ],
      introData: [
        {
          text: '公域引流',
          img: 'https://mktv-in-cdn.mockuai.com/16265115984053528.png'
        },
        {
          text: '聚粉获客',
          img: 'https://mktv-in-cdn.mockuai.com/16265115984552999.png'
        },
        {
          text: '私域流量',
          img: 'https://mktv-in-cdn.mockuai.com/16265115984675044.png'
        }
      ]
    }
  },
  methods: {
    jumpCollect() {
      window.open('/collect-scrm')
    }
  }
}
</script>


<style lang="less" scoped>
  @import '../../style/common.less';

  .private-wrapper {
    .banner-wrapper {
      position: relative;
      box-sizing: border-box;
      .pxToVw(height, 680);
      .pxToVw(padding-top, 156);
      .pxToVw(padding-left, 240);
      background-color: #00092a;
      // background-image: url(https://mktv-in-cdn.mockuai.com/16265063621935823.png);
      background-image: url(https://mktv-in-cdn.mockuai.com/16281416966898573.png);
      background-size: cover;
      background-repeat: no-repeat;
      .info {
        h3.title {
          .pxToVw(margin-bottom, 17);
          line-height: 1.4;
          .pxToVw(font-size, 60);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
        .desc {
          .pxToVw(margin-bottom, 91);
          .pxToVw(line-height, 33);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
        .actions {
          display: flex;
          align-items: center;
          .btn {
            box-sizing: border-box;
            .pxToVw(margin-right, 60);
            .pxToVw(height, 60);
            .pxToVw(line-height, 60);
            text-align: center;
            padding: 0 44*@unitVw;
            .pxToVw(border-radius, 30);
            border: 1px solid #fff;
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              font-weight: 600;
            }
            &.btn-white {
              background-color: #fff;
              color: #0F58F0;
              &:hover {
                border: none;
                background: linear-gradient(270deg, #1AD4E4 0%, #0F58F0 100%);
                color: #fff;
              }
            }
          }
        }
      }
      .banner-box {
        position: absolute;
        .pxToVw(top, 163);
        .pxToVw(right, 338);
        .pxToVw(width, 583);
        .pxToVw(height, 355);
        background-image: url(https://mktv-in-cdn.mockuai.com/16267622862671934.png);
        background-size: cover;
        background-repeat: no-repeat;
        .icon-box {
          position: absolute;
          box-sizing: border-box;
          .pxToVw(width, 92);
          .pxToVw(height, 108);
          padding: 15*@unitVw 17*@unitVw;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #fff;
          box-shadow: 5px 11px 23px 0px rgba(0, 0, 0, 0.11);
          .pxToVw(border-radius, 8);
          &:nth-child(1) {
            .pxToVw(left, -80);
            .pxToVw(top, 64);
          }
          &:nth-child(2) {
            .pxToVw(left, -45);
            .pxToVw(bottom, 25);
          }
          &:nth-child(3) {
            .pxToVw(right, -71);
            .pxToVw(top, 64);
          }
          &:nth-child(4) {
            .pxToVw(right, -38);
            .pxToVw(bottom, 25);
          }
          .icon {
            .pxToVw(width, 58);
            .pxToVw(height, 58);
          }
          .text {
            .pxToVw(font-size, 14);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    .intro-wrapper {
      box-sizing: border-box;
      .pxToVw(height, 1066);
      .pxToVw(padding-top, 85);
      .pxToVw(padding-bottom, 100);
      background-image: url(https://mktv-in-cdn.mockuai.com/16265063620297857.png);
      background-size: cover;
      background-repeat: no-repeat;
      >.title {
        text-align: center;
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .desc {
        margin: 8*@unitVw auto 0;
        .pxToVw(width, 685);
        text-align: center;
        .pxToVw(line-height, 25);
        .pxToVw(font-size, 18);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .functions {
        .pxToVw(margin-top, 87);
        .pxToVw(margin-left, 240);
        display: flex;
        align-items: flex-end;
        .function-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          &:nth-child(1) {
            .pxToVw(margin-right, 70);
            .image {
              .pxToVw(width, 474);
              .pxToVw(height, 407);
              .pxToVw(margin-bottom, 60);
            }
          }
          &:nth-child(2) {
            .pxToVw(margin-right, 41);
            .image {
              .pxToVw(width, 352);
              .pxToVw(height, 352);
              .pxToVw(margin-bottom, 81);
            }
          }
          &:nth-child(3) {
            margin-right: 0;
            .image {
              .pxToVw(width, 504);
              .pxToVw(height, 400);
              .pxToVw(margin-bottom, 55);
            }
          }
          .image {
            object-fit: cover;
          }
          .text {
            .pxToVw(width, 180);
            .pxToVw(height, 58);
            .pxToVw(line-height, 58);
            text-align: center;
            .pxToVw(border-radius, 36);
            background-color: #eaf0ff;
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .btn {
        margin: 100*@unitVw auto 0;
        .pxToVw(width, 320);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        background-color: #0f58f0;
        .pxToVw(border-radius, 30);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, .8);
        cursor: pointer;
        &:hover {
          font-weight: 500;
          color: #fff;
          box-shadow: 0px 8px 18px 0px rgba(15, 88, 240, 0.21);
        }
      }
    }
  }
</style>

